const StandardVerifiedSvg = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_23606_11162)">
      <path
        d="M13.3303 4.0058C12.7588 2.79447 11.8538 1.77728 10.7135 1.06361C9.12758 0.0737688 7.24907 -0.240684 5.42797 0.180409C3.60688 0.598769 2.05649 1.70346 1.06665 3.28939C0.0740686 4.87533 -0.240385 6.75111 0.180709 8.57494C0.601803 10.396 1.70649 11.9464 3.28969 12.9363C4.40532 13.6335 5.68501 14.0027 6.99477 14.0027H7.07954C8.38383 13.9863 9.65258 13.6089 10.7518 12.9144C11.0717 12.712 11.1647 12.291 10.9623 11.971C10.76 11.6511 10.3389 11.5581 10.019 11.7605C9.13579 12.321 8.11313 12.6246 7.06313 12.6382C5.98579 12.6519 4.93305 12.3538 4.0143 11.7824C2.73735 10.9839 1.84868 9.73705 1.51235 8.27142C1.17602 6.8058 1.42758 5.29642 2.22602 4.01947C3.87212 1.38627 7.35571 0.582363 9.98891 2.22846C10.9077 2.80268 11.635 3.62025 12.0944 4.59369C12.5428 5.54252 12.7178 6.59525 12.6002 7.63432C12.5592 8.00893 12.8272 8.34799 13.2045 8.389C13.5791 8.43002 13.9182 8.16205 13.9592 7.78471C14.1041 6.49135 13.8854 5.18432 13.3303 4.0058Z"
        fill="currentcolor"
      />
      <path
        d="M9.26162 4.62654L5.96396 7.92419L4.7417 6.70193C4.47373 6.43396 4.0417 6.43396 3.77373 6.70193C3.50576 6.9699 3.50576 7.40193 3.77373 7.6699L5.47998 9.37615C5.61396 9.51013 5.78896 9.57576 5.96396 9.57576C6.13896 9.57576 6.31396 9.51013 6.44795 9.37615L10.2269 5.59451C10.4948 5.32654 10.4948 4.89451 10.2269 4.62654C9.95889 4.3613 9.52686 4.3613 9.26162 4.62654Z"
        fill="currentcolor"
      />
    </g>
    <defs>
      <clipPath id="clip0_23606_11162">
        <rect width="14" height="14" fill="currentcolor" />
      </clipPath>
    </defs>
  </svg>
);

export default StandardVerifiedSvg;
