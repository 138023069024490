import { AnimatePresence, motion, useAnimation } from 'framer-motion';

export const AnimatedHoverContainer = ({
  children,
  /* Fit content used to make safari and firefox work */
  style = { height: '100%', cursor: 'pointer', width: 'fit-content' },
}: {
  children: React.ReactElement;
  style?: React.CSSProperties;
}) => {
  const animation = useAnimation();

  const hoverSequence = async () => {
    await animation.start({ scale: 1.03, transition: { duration: 0.1 } });
    animation.start({ scale: 1, transition: { duration: 0.1 } });
  };

  return (
    <AnimatePresence>
      <motion.div animate={animation} onHoverStart={hoverSequence} style={style}>
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
